<template xmlns="http://www.w3.org/1999/html">
  <div>
      <div class="card mt-2">
          <div class="card-header">
              Výběr vozíku
          </div>
          <div class="card-body">
             <div class="row">
                  <div class="col-2">
                      Výběr vozíku
                  </div>
                  <div class="col-10">
                    <the-autocomplete
                        placeholder="Zadejte název vozíku / evidčíslo / výrobce / model"
                        adresa="get-vzv"
                        sablona="vzv-long"
                        hodnota="id_polozky"
                        value="vzv"
                        id="vzv"
                        parametry=""
                        @ac-vzv="setVZV"
                    />
                  </div>
             </div>
             <div class="row mt-2" v-if="vybranyVozik">
                 <hr class="mt-2" />
                 <div class="col-2">
                    <img :src="$adminUrl + vybranyVozikData.foto_cesta" class="img img-fluid" />
                 </div>
                 <div class="col-3">
                      <span class="text-bold">{{ vybranyVozikData.evidcislo}} | {{ vybranyVozikData.vyrobce}} {{ vybranyVozikData.nazev_modelu }}</span>
                 </div>
                 <div class="col-7">
                   <div class="table-responsive">
                       <table class="table">
                             <tr>
                                 <th>Rok výroby</th>
                                 <td>{{ vybranyVozikData.rok_vyroby }}</td>
                                 <th>MTH</th>
                                 <td v-html="vybranyVozikData.motohodiny"></td>
                                 <th>Typ</th>
                                 <td>{{ vybranyVozikData.typ_voziku }}</td>
                             </tr>
                             <tr>
                               <th>Zdvih</th>
                               <td>{{ vybranyVozikData.zdvih }}</td>
                               <th>Nosnost</th>
                               <td v-html="vybranyVozikData.nosnost"></td>
                               <th>Hmotnost</th>
                               <td v-html="vybranyVozikData.hmotnost"></td>
                             </tr>
                       </table>
                     </div>
                 </div>
             </div>
            <div class="row mt-1" v-if="vybranyVozik">
                <div class="col-3 offset-2 text-center">
                    Zaparkováno na: <span class="text-bold">{{ vybranyVozikData.umisteni }} - {{ vybranyVozikData.kod}}</span>
                </div>
                <div class="col-4 text-center">
                    Na vozíku pracuje: <span class="text-bold">{{ vybranyVozikData.mechanik }}</span>
                </div>
                <div class="col-3 text-center">
                    Vozík je spojen s katalogem:
                      <span v-if="vybranyVozikData.id_vzv > 0" class="badge bg-success">ANO</span>
                      <span v-if="vybranyVozikData.id_vzv == 0" class="badge bg-danger">NE</span>
                </div>
            </div>
       </div>
      </div>

      <div class="card mt-2">
          <div class="card-header">
              Výběr přebírajícího zaměstnance
          </div>
          <div class="card-body">
              <div class="row">
                  <div class="col-2">
                      Pro zaměstnance
                  </div>
                  <div class="col-3">
                      <the-autocomplete
                          placeholder="Zadejte jméno / příjmení uživatele"
                          adresa="get-uzivatele"
                          sablona="uzivatele"
                          hodnota="id_uzivatele"
                          value="cele_jmeno"
                          :input-text="vybranyVozikData.mechanik"
                          id="uzivatele"
                          parametry=""
                          @ac-uzivatele="setUser"
                      />
                  </div>
              </div>
          </div>
      </div>

      <div class="card mt-2">
          <div class="card-header">
              Poznámky
          </div>
          <div class="card-body">
              <div class="row">
                  <div class="col-2">
                    Režijní položky
                  </div>
                  <div class="col-10">
                      <textarea  class="form-control" v-model="rezijniPolozkaPoznamka">

                      </textarea>
                  </div>
              </div>
              <div class="row mt-3">
                  <div class="col-2">
                      Bez náhradních dílů
                  </div>
                  <div class="col-10">
                      <the-toggle  @checkbox="checkPozadavekBezND($event)" :set-checked="pozadavekBezND" value="1"></the-toggle>
                  </div>
              </div>
          </div>
      </div>

      <div class="card mt-2">

          <div class="card-header">
            Náhradní díly
          </div>

          <div class="card-body">
              <div class="row">
                  <div class="col text-center">
                    <button type="button"
                            class="btn btn-primary"
                            name="vyhledat-podle-vzv"
                            id="vyhledat-podle-kategorie"
                            @click="ModalVyhledatPodleKategorie">
                      Podle kategorie položek
                    </button>
                                  <the-modal-dialog
                                      id="modalKategorie"
                                      title="Vyhledat podle kategorie"
                                      size="extra-large"
                                      buttons="storno">

                          <div class="card">
                            <div class="card-header">
                                Výběr kategorie
                            </div>
                            <div class="card-body">

                                <div class="row mb-3">
                                    <div class="col-12">

                                            <template v-if="vybranyVozikData.id_vzv > 0">
                                                <div class="row mb-3">
                                                    <div class="col-12">
                                                        <the-toggle  @checkbox="checkVyhledavatPouzePridelenoVZV($event, true)" :set-checked="vyhledavatPouzePridelenoVZV" value="1"></the-toggle>
                                                        Vyhledávat pouze z položek, které jsou přiděleny k <strong>{{ vybranyVozikData.evidcislo}} | {{ vybranyVozikData.vyrobce}} {{ vybranyVozikData.nazev_modelu }}</strong>
                                                    </div>
                                                </div>
                                                <hr />
                                            </template>

                                            <div class="btn-group"
                                                 role="group"
                                                 aria-label="Basic radio toggle button group"
                                                 v-for="(item, id) in vyberPodleKategorieBreadCrumb"
                                            >
                                                  <label
                                                      @click="VyhledatPodleKategorie(item.id_kategorie, item.pocet_podkategorii, item.nazev_kategorie, item.poradi)"
                                                      class="btn btn-outline-secondary">
                                                    {{ item.nazev_kategorie }}
                                                  </label>
                                            </div>
                                    </div>
                                </div>

                                <div class="row">

                                  <div v-for="(kategorieItem, id) in vyberPodleKategorieKategorie" class="card col-xl-3 col-sm-6 col-12">
                                      <div class="card-body" @click="VyhledatPodleKategorie(kategorieItem.id_kategorie, kategorieItem.pocet_podkategorii, kategorieItem.nazev_kategorie)">
                                        <div class="text-bold text-center">
                                          <h5>{{ kategorieItem.nazev_kategorie }}</h5>
                                        </div>
                                        <hr />
                                        <div class="table-responsive">
                                            <table class="table">
                                              <tr>
                                                <th>Počet podkategorií:</th>
                                                <td class="text-center">
                                                      <span class="badge badge-pill bg-success" v-if="kategorieItem.pocet_podkategorii > 0">
                                                          {{ kategorieItem.pocet_podkategorii }}
                                                      </span>
                                                      <span class="badge badge-pill bg-danger" v-if="kategorieItem.pocet_podkategorii == 0">
                                                          {{ kategorieItem.pocet_podkategorii }}
                                                      </span>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>
                                                  Počet položek:
                                                </th>
                                                <td class="text-center">
                                                      <span class="badge badge-pill bg-success"  v-if="kategorieItem.pocet_polozek > 0">
                                                          {{ kategorieItem.pocet_polozek }}
                                                      </span>
                                                      <span class="badge badge-pill bg-danger"  v-if="kategorieItem.pocet_polozek == 0">
                                                          {{ kategorieItem.pocet_polozek }}
                                                      </span>
                                                </td>
                                              </tr>
                                            </table>
                                        </div>
                                      </div>
                                    </div>

                                </div>

                            </div>

                          </div>

                          <div class="card mt-2">
                              <div class="card-header">
                                  Náhradní dily
                              </div>
                              <div class="card-body">
                                  <div v-if="vyberPodleKategorieKategoriePolozkyPocet > 20">
                                    K dispozici je velké množstí položek <strong>{{ vyberPodleKategorieKategoriePolozkyPocet }}</strong>, maximální počet k výpisu je <strong>20</strong>.
                                  </div>
                                  <div v-if="vyberPodleKategorieKategoriePolozkyPocet <= 20">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead class="thead-light">
                                                  <tr>
                                                    <th class="text-center">Foto</th>
                                                    <th class="text-center">Skladové číslo</th>
                                                    <th class="text-center">Náhradní díl</th>
                                                    <th class="text-center">Počet skladem<br /> (Nové)</th>
                                                    <th class="text-center">Typ požadavku</th>
                                                    <th class="text-center">Počet MJ</th>
                                                    <th class="text-center">Vybrat</th>
                                                  </tr>
                                              </thead>

                                              <tbody>
                                              <tr v-for="(nahradniDil, id) in vyberPodleKategorieKategoriePolozky">
                                                    <td class="text-center">
                                                      <img :src="$adminUrl + nahradniDil.foto_cesta" class="img img-fluid" alt="Responsive image">
                                                    </td>
                                                    <td class="text-center align-middle" v-bind:class="{pouzita: nahradniDil.pouzita == 1}">
                                                      <detail-polozky
                                                          :id="'detailPolozky-' + nahradniDil.id_polozky"
                                                          :id-polozky="nahradniDil.id_polozky"
                                                          :skladove-cislo="nahradniDil.skladove_cislo" >

                                                      </detail-polozky>
                                                    </td>
                                                    <td class="align-middle">
                                                      {{ nahradniDil.nazev_polozky }}
                                                    <td class="align-middle" v-if="jePolozkaSkladem(nahradniDil.skladove_zasoby) == true">
                                                        <strong>E:</strong> {{ nahradniDil.skladove_zasoby[1][0] }}/{{ nahradniDil.skladove_zasoby[1][1] }},
                                                        <strong>F:</strong> {{ nahradniDil.skladove_zasoby[16][0] }}/{{ nahradniDil.skladove_zasoby[16][1] }},
                                                        <strong>Praha:</strong> {{ nahradniDil.skladove_zasoby[3][0] }}/{{ nahradniDil.skladove_zasoby[3][1] }}
                                                    </td>
                                                    <td v-else class="text-center align-middle">
                                                        <span class="badge bg-danger">Položka není skladem!</span>
                                                    </td>
                                                    <td class="text-center align-middle">
                                                        <select class="form-select" v-model="nahradniDil.id_typu_odberu">
                                                            <option v-for="typOdberu in typyOdberu" :value="typOdberu.id_typu_odberu" :selected="typOdberu.id_typu_odberu == 'sklad'">
                                                                {{ typOdberu.typ_odberu }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td :key="'spinner-' + spinnerKey" class="align-middle">
                                                        <template v-if="nahradniDil.typ_polozkovani == 'nepol'">
                                                            <input type="text" class="form-control"  v-model="nahradniDil.pocet_mj" />
                                                        </template>
                                                        <template v-else>
                                                          <NumberInputSpinner
                                                              :min="1"
                                                              :max="1000"
                                                              :step="1"
                                                              :integerOnly="true"
                                                              v-model="nahradniDil.pocet_mj"
                                                          />
                                                        </template>
                                                    </td>
                                                    <td class="text-center align-middle">
                                                      <button class="btn btn-success" @click="vybratNahradniDil(nahradniDil.id_polozky, id)">
                                                          Vybrat
                                                      </button>
                                                    </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                  </div>
                              </div>
                          </div>

                    </the-modal-dialog>
                  </div>
                  <div class="col text-center">
                    <button type="button"
                            class="btn btn-primary"
                            name="vyhledat-podle-skladoveho-cisla"
                            id="vyhledat-podle-skladoveho-cisla"
                            @click="VyhledatPodleSkladovehoCisla">
                      Podle skladového čísla
                    </button>
                                  <the-modal-dialog
                                      id="modalSkladoveCislo"
                                      title="Vyhledat podle skladového čísla"
                                      size="extra-large"
                                      buttons="ulozit,storno">

                                        <div class="card" :key="'modal-' + modalSkladoveCisloKey">
                                            <div class="card-header text-left">
                                                Výběr položky
                                            </div>
                                              <div class="card-body">
                                                  <template v-if="vybranyVozikData.id_vzv > 0">
                                                      <div class="row mb-3">
                                                          <div class="col-12">
                                                              <the-toggle @checkbox="checkVyhledavatPouzePridelenoVZV($event, true)" :set-checked="vyhledavatPouzePridelenoVZV" value="1"></the-toggle>
                                                              Vyhledávat pouze z položek, které jsou přiděleny k <strong>{{ vybranyVozikData.evidcislo}} | {{ vybranyVozikData.vyrobce}} {{ vybranyVozikData.nazev_modelu }}</strong>
                                                          </div>
                                                      </div>
                                                      <hr />
                                                  </template>
                                                  <div class="row">
                                                      <div class="col-2">
                                                          Skladové číslo
                                                      </div>
                                                      <div  class="col-10">

                                                            <the-autocomplete
                                                                v-if="this.vyhledavatPouzePridelenoVZV == 0"
                                                                placeholder="Zadejte skladové číslo / název položky"
                                                                adresa="get-nd"
                                                                sablona="nd-long"
                                                                hodnota="id_polozky"
                                                                value="nd"
                                                                id="nd"
                                                                parametry=""
                                                                @ac-nd="setND"
                                                            />

                                                            <the-autocomplete
                                                                v-if="this.vyhledavatPouzePridelenoVZV == 1"
                                                                placeholder="Zadejte skladové číslo / název položky"
                                                                adresa="get-nd"
                                                                sablona="nd-long"
                                                                hodnota="id_polozky"
                                                                value="nd"
                                                                id="nd"
                                                                :parametry="'&id-vzv=' + vybranyVozikData.id_vzv"
                                                                @ac-nd="setND"
                                                            />

                                                      </div>
                                                  </div>
                                                        <template v-if="vybranaPolozka == true">
                                                              <div class="row mt-4 mb-4">
                                                                  <div class="col-2">
                                                                      <img :src="$adminUrl + vybranaPolozkaData.foto_cesta" class="img img-thumbnail" />
                                                                  </div>
                                                                  <div class="col-6 text-left">
                                                                        <detail-polozky
                                                                            :id="'detailPolozky-' + vybranaPolozkaData.id_polozky"
                                                                            :id-polozky="vybranaPolozkaData.id_polozky"
                                                                            :skladove-cislo="vybranaPolozkaData.skladove_cislo"
                                                                            :key="Math.random()">

                                                                        </detail-polozky>
                                                                    <strong class="text-left">{{ vybranaPolozkaData.nazev_polozky }}</strong>
                                                                  </div>
                                                                  <div class="col-4">
                                                                      Skladem
                                                                      <div class="table-responsive">
                                                                          <table class="table table-bordered">
                                                                            <thead class="thead-light">
                                                                                <tr class="row">
                                                                                    <th class="text-center col-4">E</th>
                                                                                    <th class="text-center col-4">F</th>
                                                                                    <th class="text-center col-4">Praha</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr class="row">
                                                                                    <template v-if="jePolozkaSkladem(this.vybranaPolozkaZasoby) == true">
                                                                                        <td class="text-center col-4">{{ this.vybranaPolozkaZasoby[1][0] }} / {{ this.vybranaPolozkaZasoby[1][1] }}</td>
                                                                                        <td class="text-center col-4">{{ this.vybranaPolozkaZasoby[16][0]  }} / {{ this.vybranaPolozkaZasoby[16][1]  }}</td>
                                                                                        <td class="text-center col-4">{{ this.vybranaPolozkaZasoby[3][0] }} / {{ this.vybranaPolozkaZasoby[3][1] }}</td>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <td class="text-center"><span class="badge bg-danger">Položka není skladem!</span></td>
                                                                                    </template>
                                                                                </tr>
                                                                            </tbody>
                                                                          </table>
                                                                      </div>
                                                                      Rezervace
                                                                          <div class="table-responsive">
                                                                              <table class="table table-bordered">
                                                                                  <thead class="thead-light">
                                                                                      <tr class="row">
                                                                                          <th class="text-center col-4">E</th>
                                                                                          <th class="text-center col-4">F</th>
                                                                                          <th class="text-center col-4">Praha</th>
                                                                                      </tr>
                                                                                  </thead>
                                                                                  <tbody>
                                                                                      <tr class="row">
                                                                                          <template v-if="jePolozkaRezervovana(this.vybranaPolozkaRezervace) == true">
                                                                                              <td class="text-center col-4">{{ this.vybranaPolozkaRezervace[1][0] }} / {{ this.vybranaPolozkaRezervace[1][1] }}</td>
                                                                                              <td class="text-center col-4">{{ this.vybranaPolozkaRezervace[16][0]  }} / {{ this.vybranaPolozkaRezervace[16][1]  }}</td>
                                                                                              <td class="text-center col-4">{{ this.vybranaPolozkaRezervace[3][0] }} / {{ this.vybranaPolozkaRezervace[3][1] }}</td>
                                                                                          </template>
                                                                                          <template v-else>
                                                                                              <td class="text-center"><span class="badge bg-danger">Položka není rezervovana!</span></td>
                                                                                          </template>
                                                                                      </tr>
                                                                                  </tbody>
                                                                              </table>
                                                                          </div>
                                                                  </div>
                                                              </div>
                                                              <div class="row mt-4 mb-4">
                                                                  <div class="col-12">
                                                                      Objednáno
                                                                      <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                          <thead class="thead-light">
                                                                          <tr class="row">
                                                                            <th class="text-center col-2">Číslo objednávky</th>
                                                                            <th class="text-center col-2">Datum objednání</th>
                                                                            <th class="text-center col-2">Požadavek zadal</th>
                                                                            <th class="text-center col-2">Druh objednávky</th>
                                                                            <th class="text-center col-2">Sklad / vozík</th>
                                                                            <th class="text-center col-2">Počet MJ</th>
                                                                          </tr>
                                                                          </thead>
                                                                          <tbody>
                                                                          <template v-if="this.vybranaPolozkaObjednana.length > 0">
                                                                            <tr v-for="objednavka in this.vybranaPolozkaObjednana" class="row">
                                                                              <td class="text-center col-2">{{ objednavka.cislo_objednavky }}</td>
                                                                              <td class="text-center col-2">{{ objednavka.datum_objednani }}</td>
                                                                              <td class="text-center col-2">{{ objednavka.pozadavek_zadal }}</td>
                                                                              <td class="text-center col-2">{{ objednavka.prirazeno }}</td>
                                                                              <td class="text-center col-2">{{ objednavka.sklad_vozik }}</td>
                                                                              <td class="text-center col-2">{{ objednavka.pocet_mj }}</td>
                                                                            </tr>
                                                                          </template>
                                                                          <template v-else>
                                                                            <tr class="row">
                                                                              <td class="text-center"><span class="badge bg-danger">Položka není objednaná!</span></td>
                                                                            </tr>
                                                                          </template>
                                                                          </tbody>
                                                                        </table>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                              <div class="row mt-4 mb-4" :key="'the-toggle-' + keyToggle">

                                                                  <div class="col text-center">
                                                                      <the-toggle @checkbox="checkTyp($event, 'sklad')" :set-checked="theToggleObj['sklad']" class="typ-toggle" value="sklad"></the-toggle>
                                                                      Vzít ze skladu
                                                                  </div>

                                                                  <div class="col text-center">
                                                                      <the-toggle @checkbox="checkTyp($event, 'nakup')" :set-checked="theToggleObj['nakup']" class="typ-toggle" value="nakup"></the-toggle>
                                                                      Objednat
                                                                  </div>

                                                              </div>
                                                        </template>
                                                  </div>
                                              </div>
                                        <div class="card mt-2" :key="'modal-' + modalSkladoveCisloKey + 1">
                                            <div class="card-header text-left">
                                              Ostatní
                                            </div>
                                            <div class="card-body">
                                                <div class="row mt-2">
                                                  <div class="col-1">
                                                      Použité
                                                  </div>
                                                  <div class="col-2">
                                                      <the-toggle @checkbox="checkPouzita($event)"  value="1"></the-toggle>
                                                  </div>
                                                  <div class="col-1">
                                                      Počet MJ
                                                      <template v-if="vybranaPolozkaData.typ_polozkovani == 'nepol'">
                                                          &nbsp;/&nbsp;Nezadaný počet <the-toggle @checkbox="checkNezadanyPocet($event)" value="1"></the-toggle>
                                                      </template>
                                                  </div>
                                                  <div class="col-3">
                                                      <template v-if="vybranaPolozkaData.typ_polozkovani == 'nepol'">
                                                          <input type="text" class="form-control"  v-model="modalSkladoveCisloData['pocet_mj']"  v-bind:readonly="pozadavekPolozkaNezadanyPocet == 1" />
                                                      </template>
                                                      <template v-else>
                                                          <NumberInputSpinner
                                                            :min="1"
                                                            :max="1000"
                                                            :step="1"
                                                            :integerOnly="true"
                                                            v-model="modalSkladoveCisloData['pocet_mj']"
                                                          />
                                                      </template>

                                                  </div>
                                                  <div class="col-1">
                                                      Sklad
                                                  </div>
                                                  <div class="col-3">
                                                      <select class="form-select" v-model="modalSkladoveCisloData['id_skladu']">
                                                        <option value="16" :selected="modalSkladoveCisloData['id_skladu'] == 16">
                                                          Sklad F
                                                        </option>
                                                        <option value="1" :selected="modalSkladoveCisloData['id_skladu'] == 1">
                                                          Sklad E
                                                        </option>
                                                      </select>
                                                  </div>
                                                  <div class="col-1" v-if="vybranaPolozkaData.merna_jednotka !== undefined">
                                                      <div class="badge bg-success text-wrap text-uppercase font-size-90">
                                                        {{ vybranaPolozkaData.merna_jednotka }}
                                                      </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>

                                  </the-modal-dialog>
                  </div>
              </div>
          </div>

          <hr />

          <div class="row">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead class="thead-light">
                      <tr>
                          <th class="text-center">Foto</th>
                          <th class="text-center">Skladové číslo</th>
                          <th class="text-center">Náhradní díl</th>
                          <th class="text-center">Počet MJ</th>
                          <th class="text-center">Sklad</th>
                          <th class="text-center">Použité</th>
                          <th class="text-center">Typ požadavku</th>
                          <th class="text-center">Odebrat</th>
                      </tr>
                  </thead>

                  <tbody>
                      <tr v-for="(nahradniDil, id) in nahradniDily">
                          <td class="text-center">
                              <img :src="$adminUrl + nahradniDil.foto_cesta" class="img img-fluid" alt="Responsive image">
                          </td>
                          <td class="text-center align-middle" v-bind:class="{pouzita: nahradniDil.pouzita == 1}">
                              <detail-polozky
                                  :id="'detailPolozky-' + nahradniDil.id_polozky"
                                  :id-polozky="nahradniDil.id_polozky"
                                  :skladove-cislo="nahradniDil.skladove_cislo" >

                              </detail-polozky>
                          </td>
                          <td class="align-middle">
                              {{ nahradniDil.nazev_polozky }}
                          </td>
                          <td :key="'spinner-' + spinnerKey" class="align-middle text-center" style="width:150px">
                              <template v-if="nahradniDil.typ_polozkovani == 'nepol'">
                                  <template v-if="nahradniDil.nezadany_pocet == 1">
                                      <span class="badge bg-warning text-dark">Nezadaný počet</span>
                                  </template>
                                  <template v-else>
                                      <input type="text" class="form-control"  v-model="nahradniDil.pocet_mj" />
                                  </template>
                              </template>
                              <template v-else>
                                  <NumberInputSpinner
                                      :min="1"
                                      :max="1000"
                                      :step="1"
                                      :integerOnly="true"
                                      v-model="nahradniDil.pocet_mj"
                                  />
                              </template>
                          </td>
                          <td>
                            <select class="form-select align-middle">
                                <option value="16" :selected="nahradniDil.id_skladu == 16">F</option>
                                <option value="1" :selected="nahradniDil.id_skladu == 1">E</option>
                            </select>
                          </td>
                          <td  class="text-center align-middle" style="width:100px">
                              <the-toggle @checkbox="checkPouzitaSeznamND($event, id)"  value="1" :set-checked="nahradniDil.pouzita"></the-toggle>
                          </td>
                          <td class="text-center align-middle" style="width:150px">
                              <select class="form-select" v-model="nahradniDil.id_typu_odberu">
                                  <option v-for="typOdberu in typyOdberu" :value="typOdberu.id_typu_odberu" :selected="nahradniDil.id_typu_odberu == typOdberu.id_typu_odberu">
                                      {{ typOdberu.typ_odberu}}
                                  </option>
                              </select>
                          </td>
                          <td class="text-center align-middle" style="width : 100px">
                              <button class="btn btn-danger" @click="odebratPolozku(nahradniDil.key)">
                                Odebrat
                              </button>
                          </td>
                      </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </div>

      <div class="col-12 text-center mt-3 mb-5">
          <button type="button" class="btn btn-success btn-lg btn-red" @click="vytvoritPozadavek" >Vytvořit požadavek</button>
      </div>

  </div>
</template>
<script>

import TheAutocomplete from "@/components/form/TheAutocomplete";
import TheModalDialog from "@/components/TheModalDialog";
import TheToggle from "@/components/form/TheToggle";
import DetailPolozky from "@/views/DetailPolozky";
import NumberInputSpinner from 'vue-number-input-spinner'

import axiosApiPost from "../axiosApiMethod";
import {bus} from "../main.js";

export default {
  name: "PozadavkyNaVydejNovy",
  components: {TheAutocomplete, TheModalDialog, TheToggle, NumberInputSpinner, DetailPolozky},
  data () {
      return {
          postData: {},

          rezijniPolozkaPoznamka: '',

          vyhledavatPouzePridelenoVZV : false,
          pozadavekBezND  : 0,
          pozadavekPolozkaNezadanyPocet : 0,

          //
          // Vyber voziku
              vybranyVozik : false,
              vybranyVozikData : {},

          // Vyber uzivatele
              proZamestnanceKey : 0,

          //
          // Ciselniky
              odebirajiciZamestnanci: [],
              sklady : [],
              typyOdberu : [],

          //
          // Polozky
              nahradniDily : [],

              spinnerKey : 1,
              modalSkladoveCisloKey : 1,

              theToggleObj: {},
              keyToggle: 0,

          //
          // Modalni okna

              //
              // Vyber podle kategorie
                  vyberPodleKategorieKategorie : {},
                  vyberPodleKategorieKategoriePolozkyPocet : 0,
                  vyberPodleKategorieKategoriePolozky : {},

                  vyberPodleKategorieBreadCrumb : {},
                  vyberPodleKategorieBreadCrumbCounter : 0,

              // Vyber podle nahradniho dilu
                  modalSkladoveCisloData: {},
                  vybranaPolozka : false,
                  vybranaPolozkaData : [],
                  vybranaPolozkaZasoby : [],
                  vybranaPolozkaObjednana : [],
                  vybranaPolozkaRezervace : [],
      }
  },
  created() {

      this.modalSkladoveCisloData.skladove_cislo = '';
      this.modalSkladoveCisloData.pocet_mj = 1;
      this.modalSkladoveCisloData.typ_odberu = '';
      this.modalSkladoveCisloData.pouzita = 0;
      this.modalSkladoveCisloData.id_skladu = 16;

      //
      // Ciselniky

        // Odebirajici zamestnanci
          axiosApiPost('get-odebirajici-zamestnance', {}, 'data').then( result => {
              result.forEach(odebirajiciZamestnanec => {

                  let oz = {
                      jmeno :  odebirajiciZamestnanec.jmeno,
                      prijmeni :  odebirajiciZamestnanec.prijmeni,
                      id_uzivatele :  odebirajiciZamestnanec.id_uzivatele,
                  };

                  this.odebirajiciZamestnanci.push(oz);
              });
          });

        // Typy odberu
          axiosApiPost('get-typy-odberu', {}, 'data').then( result => {
              this.typyOdberu = result;
          });

        // Sklady
          axiosApiPost('get-sklady', {}, 'data').then( result => {
              this.sklady = result;
          });

      // Dialog vyber ND
        bus.$on('modalDialogSave-' + 'modalSkladoveCislo', (result) =>
        {
          if(result == true)
          {
              let idPolozky = this.postData['nd'];

              if(typeof(idPolozky) == 'undefined')
              {
                  this.$root.$emit('alertMessage', {text: 'Nemáte nastavenou novou položku!', color: 'danger', autoHide: 0, strong: 'Chyba!'})
                  return '';
              }
              else
              {
                  axiosApiPost('get-nahradni-dily', {id_polozky: idPolozky}, 'data').then( result => {

                    let nalezenoKey = '';

                    $.each(this.nahradniDily, function (key, value)
                    {
                        if(value.id_polozky == result[0].id_polozky)
                        {
                            if(value.id_typu_odberu == modalSkladoveCisloDataTemp.id_typu_odberu)
                            {
                                nalezenoKey = key
                            }
                        }
                    });

                    if(nalezenoKey === '')
                    {
                        result.forEach(nahradniDil => {

                          let nd = {
                            id_polozky: nahradniDil.id_polozky,
                            skladove_cislo:  nahradniDil.skladove_cislo,
                            nazev_polozky:  nahradniDil.nazev_polozky,
                            foto_cesta:  nahradniDil.foto_cesta,
                            pocet_mj: this.modalSkladoveCisloData.pocet_mj,
                            pouzita: this.modalSkladoveCisloData.pouzita,
                            id_skladu: this.modalSkladoveCisloData.id_skladu,
                            id_typu_odberu: this.modalSkladoveCisloData.id_typu_odberu,
                            key: nahradniDil.id_polozky + '-' + this.modalSkladoveCisloData.pouzita,
                            typ_polozkovani: nahradniDil.typ_polozkovani,
                            nezadany_pocet: this.pozadavekPolozkaNezadanyPocet,
                          };

                          this.nahradniDily.push(nd);
                        });
                    }
                    else
                    {
                        this.nahradniDily[nalezenoKey].pocet_mj = parseInt(this.nahradniDily[nalezenoKey].pocet_mj + this.modalSkladoveCisloData.pocet_mj);
                        this.spinnerKey++;
                    }

                    this.modalSkladoveCisloData.skladove_cislo = '';
                    this.modalSkladoveCisloData.pocet_mj = 1;
                    this.modalSkladoveCisloData.pouzita = 0;
                    this.modalSkladoveCisloData.id_skladu = 16;
                  });

                  this.vybranaPolozka = false;
              }
          }
      });
  },
  methods : {
      VyhledatPodleSkladovehoCisla()
      {
          this.modalSkladoveCisloData.skladove_cislo = '';
          this.modalSkladoveCisloData.pocet_mj = 1;
          this.modalSkladoveCisloData.id_skladu = 16;

          this.modalSkladoveCisloKey++;

          this.vybranaPolozka = false;
          this.vybranaPolozkaData = [];

          bus.$emit('modalSkladoveCislo', {});
      },

      //
      // Modalni okna

        //
        // vyber nahradniho dilu podle nazvu
            vybratNahradniDil(idPolozky, id)
            {
                axiosApiPost('get-nahradni-dily', {id_polozky: idPolozky}, 'data').then( result => {

                  let nalezenoKey = '';

                  $.each(this.nahradniDily, function (key, value)
                  {
                      if(value.id_polozky == result[0].id_polozky)
                      {
                          if(value.id_typu_odberu == this.vyberPodleKategorieKategoriePolozky[id].id_typu_odberu)
                          {
                              nalezenoKey = key
                          }
                      }
                  });

                  if(nalezenoKey === '')
                  {
                      result.forEach(nahradniDil => {

                          let nd = {
                              id_polozky: nahradniDil.id_polozky,
                              skladove_cislo:  nahradniDil.skladove_cislo,
                              nazev_polozky:  nahradniDil.nazev_polozky,
                              foto_cesta:  nahradniDil.foto_cesta,
                              pocet_mj: this.vyberPodleKategorieKategoriePolozky[id].pocet_mj,
                              pouzita: this.vyberPodleKategorieKategoriePolozky[id].pouzita,
                              id_typu_odberu: this.vyberPodleKategorieKategoriePolozky[id].id_typu_odberu,
                              key: nahradniDil.id_polozky + '-' + this.vyberPodleKategorieKategoriePolozky[id].pouzita,
                              typ_polozkovani: nahradniDil.typ_polozkovani,
                              nezadany_pocet: this.pozadavekPolozkaNezadanyPocet,
                          };

                          this.nahradniDily.push(nd);
                      });
                  }
                  else
                  {
                      this.nahradniDily[nalezenoKey].pocet_mj = parseInt(this.nahradniDily[nalezenoKey].pocet_mj + this.modalSkladoveCisloData.pocet_mj);
                      this.spinnerKey++;
                  }

                  this.modalSkladoveCisloData.skladove_cislo = '';
                  this.modalSkladoveCisloData.pocet_mj = 1;
                  this.modalSkladoveCisloData.pouzita = 0;
                });
            },

        //
        // Vyhledat podle kategorie
            ModalVyhledatPodleKategorie()
            {
                bus.$emit('modalKategorie', {});
                this.vyberPodleKategorieBreadCrumb = [];
                this.vyberPodleKategorieBreadCrumbCounter = 0;
                this.VyhledatPodleKategorie(0);
            },
            VyhledatPodleKategorie(idKategorie, pocetPodkategorii, nazevKategorie, poradi)
            {
                this.$root.$emit('showLoader', {show: true});

                var idVZV = null;
                if(this.vybranyVozikData.id_vzv > 0 && this.vyhledavatPouzePridelenoVZV == true)
                {
                    idVZV = this.vybranyVozikData.id_vzv;
                }

                /*if(pocetPodkategorii == 0)
                {
                    this.$root.$emit('alertMessage', {text: 'Níže už jít nemůžeme!', color: 'danger', autoHide: 0, strong: 'Chyba!'})
                    this.$root.$emit('showLoader', {show: false});
                    return;
                }*/


                if(!(idKategorie > 0))
                {
                    this.vyberPodleKategorieBreadCrumb = [];

                    this.vyberPodleKategorieBreadCrumb[0] = {
                        nazev_kategorie : 'Hlavní kategorie',
                        id_kategorie : 0,
                        pocet_podkategorii : 99999,
                        poradi : 1,
                    };
                }
                else
                {
                  _.remove(this.vyberPodleKategorieBreadCrumb, function(item) {
                      return poradi <= item.poradi
                  });

                   this.vyberPodleKategorieBreadCrumb[this.vyberPodleKategorieBreadCrumb.length] = {
                      nazev_kategorie : nazevKategorie,
                      id_kategorie : idKategorie,
                      pocet_podkategorii : pocetPodkategorii,
                      poradi : this.vyberPodleKategorieBreadCrumb.length + 1,
                    };

                }

                //
                // Kategorie
                    if(pocetPodkategorii > 0 || idKategorie == 0)
                    {
                        axiosApiPost('get-kategorie', { id_rodice: idKategorie, id_vzv: idVZV}, 'data').then( result => {
                        this.vyberPodleKategorieKategorie = result;
                      });
                    }
                    else
                    {
                        this.vyberPodleKategorieKategorie = null;
                    }

                //
                // Polozky
                    axiosApiPost('get-nahradni-dily-pocet', { id_kategorie: idKategorie, id_vzv: idVZV}, 'data').then( resultPocet => {
                        this.vyberPodleKategorieKategoriePolozkyPocet = resultPocet[0]['pocet_polozek'];

                        if(this.vyberPodleKategorieKategoriePolozkyPocet <= 20)
                        {
                            axiosApiPost('get-nahradni-dily', { id_kategorie: idKategorie, id_vzv: idVZV, vcetne_podkategorii: true, vcetne_skladovych_zasob: 1, vybrane_sklady:'1,16,3' }, 'data').then( resultPolozky => {
                                this.vyberPodleKategorieKategoriePolozky = resultPolozky;
                            });
                        }

                        this.$root.$emit('showLoader', {show: false});
                    });
            },

        //
        // Vyhledavani
            setND(value)
            {
                this.postData['nd'] = value;
                this.vybranaPolozka = true;

                axiosApiPost('get-nahradni-dily', { id_polozky: value}, 'data').then( result => {
                    this.vybranaPolozkaData = result[0];

                    axiosApiPost('get-sklady-zasoby', { id_polozky: result[0]['id_polozky'], vybrane_sklady: '1,16,3'}, 'data').then( resultZasoby => {
                        this.vybranaPolozkaZasoby = resultZasoby['skladove_zasoby'][result[0]['id_polozky']];

                        if(this.jePolozkaSkladem(this.vybranaPolozkaZasoby, 16))
                        {
                            this.theToggleObj['sklad'] = true;
                            this.theToggleObj['nakup'] = false;
                            this.modalSkladoveCisloData.id_typu_odberu = 'sklad';
                            this.keyToggle++;
                        }
                        else
                        {
                            this.theToggleObj['nakup'] = true;
                            this.theToggleObj['sklad'] = false;
                            this.modalSkladoveCisloData.id_typu_odberu = 'nakup';
                            this.keyToggle++;
                        }

                        this.vybranaPolozkaObjednana = resultZasoby['objednavky'];

                        this.vybranaPolozkaRezervace = resultZasoby['rezervace'];

                    });
                });
            },

            setVZV(value)
            {
                this.postData['id_polozky_vzv'] = value;

                axiosApiPost('get-voziky', { id_polozky: value}, 'data').then( result => {
                    this.vybranyVozikData = result[0];
                    this.vybranyVozik = true;
                });
            },

            setUser(value)
            {
                this.vybranyVozikData.id_uzivatele_mechanika = value;
                this.proZamestnanceKey++;
            },

      odebratPolozku(key)
      {
          $.each(this.nahradniDily, (xKey, xValue) => {
              if(xValue.key == key)
              {
                  this.nahradniDily.splice(xKey, 1);
              }
          });
      },

      //
      // Toggle
          checkTyp(event, key)
          {
              let obj = this.theToggleObj;
              $.each(obj, function (keyObj, value) {
                  obj[keyObj] = false;
              });
              if (event == true)
              {
                  obj[key] = true;
                  this.modalSkladoveCisloData.id_typu_odberu = key;
              }
              this.theToggleObj = obj;
              this.keyToggle++;
          },
          checkPouzita(event)
          {
              if(event == true)
              {
                  this.modalSkladoveCisloData.pouzita = true;
              }
              else
              {
                  this.modalSkladoveCisloData.pouzita = false;
              }
          },
          checkVyhledavatPouzePridelenoVZV(event)
          {
              if(event == true)
              {
                  this.vyhledavatPouzePridelenoVZV = true;
              }
              else
              {
                  this.vyhledavatPouzePridelenoVZV = false;
              }
          },
          checkPouzitaSeznamND(event, key)
          {
              if(event == true)
              {
                this.nahradniDily[key].pouzita = true;
              }
              else
              {
                this.nahradniDily[key].pouzita = false;
              }
          },
          checkPozadavekBezND(event)
          {
              if(event == true)
              {
                  this.pozadavekBezND = 1;
              }
              else
              {
                  this.pozadavekBezND = 0;
              }
          },
          checkNezadanyPocet(event)
          {
              if(event == true)
              {
                  this.pozadavekPolozkaNezadanyPocet = 1;
                  this.modalSkladoveCisloData['pocet_mj'] = 1;
              }
              else
              {
                  this.pozadavekPolozkaNezadanyPocet = 0;
              }
          },

      //
      // Vytvoreni pozadavku
          vytvoritPozadavek()
          {
              this.postData['id_uzivatele'] =  localStorage.getItem('userId');
              this.postData['id_odberatele'] = this.vybranyVozikData.id_uzivatele_mechanika;
              this.postData['poznamka'] = this.rezijniPolozkaPoznamka;
              this.postData['polozky'] = [];

              // Kontrola
              if(!(this.postData['id_uzivatele'] > 0))
              {
                  this.$root.$emit('alertMessage', {text: 'Není vyplněný tvůrce!', color: 'danger', autoHide: 0, strong: 'Chyba!'})
                  return;
              }
              if(!(this.postData['id_odberatele'] > 0))
              {
                  this.$root.$emit('alertMessage', {text: 'Není vyplněný odběratel!', color: 'danger', autoHide: 0, strong: 'Chyba!'})
                  return;
              }

              let polozky = [];
              $.each(this.nahradniDily, (key, value) => {
                  let polozka = {};

                  polozka.id_polozky = value['id_polozky'];
                  polozka.pocet_mj = value['pocet_mj'];
                  polozka.pouzita = value['pouzita'];
                  polozka.id_typu_odberu = value['id_typu_odberu'];
                  polozka.nezadany_pocet = value['nezadany_pocet'];
                  polozka.id_skladu = value['id_skladu'];

                  polozky.push(polozka);
              });

              this.postData['polozky'] = JSON.stringify(polozky);

              this.postData['bez_polozek'] = this.pozadavekBezND;

              axiosApiPost('vytvor-pozadavek', this.postData, 'status').then( result => {
                  if(result == 'ok')
                  {
                    this.$router.push('/pozadavky-intralogistika-aktivni');
                  }
              });
          },

      // Ostatni
          jePolozkaSkladem(polozkaZasoby, idSkladu)
          {
              let jeSkladem = true;

              if(typeof(polozkaZasoby[1]) === "undefined" && typeof(polozkaZasoby[16]) === "undefined" && typeof(polozkaZasoby[3]) === "undefined")
              {
                  return false;
              }

              if(idSkladu > 0)
              {
                  if(polozkaZasoby[16][0] == 0 && polozkaZasoby[16][1] == 0)
                  {
                      return false;
                  }
                  return true;
              }
              else
              {
                  if(polozkaZasoby[1][0] == 0 && polozkaZasoby[1][1] == 0 && polozkaZasoby[16][0] == 0 && polozkaZasoby[16][1] == 0 && polozkaZasoby[3][0] == 0 && polozkaZasoby[3][1] == 0)
                  {
                    jeSkladem = false;
                  }
                  return jeSkladem;
              }
          },
          jePolozkaRezervovana(polozkaRezervace, idSkladu)
          {
            let jeSkladem = true;

            if(typeof(polozkaRezervace[1]) === "undefined" && typeof(polozkaRezervace[16]) === "undefined" && typeof(polozkaRezervace[3]) === "undefined")
            {
                return false;
            }

            if(idSkladu > 0)
            {
              if(polozkaRezervace[16][0] == 0 && polozkaRezervace[16][1] == 0)
              {
                  return false;
              }
              return true;
            }
            else
            {
              if(polozkaRezervace[1][0] == 0 && polozkaRezervace[1][1] == 0 && polozkaRezervace[16][0] == 0 && polozkaRezervace[16][1] == 0 && polozkaRezervace[3][0] == 0 && polozkaRezervace[3][1] == 0)
              {
                  jeSkladem = false;
              }
              return jeSkladem;
            }
          }
  }
}
</script>

<style scoped>

.img {
  max-width: 60px;
}

.pouzita {
  background-color: #00B5B8;
}

</style>